/* 
* dashboardv2.directive.js
* 16-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* Versie 2 van een docentendashboard
*/

dynalearn.directive('dashboardv2', ['sihwlog', 'sihwWait', function (sihwlog, sihwWait) {
    let log = sihwlog.logLevel('debug');

    return {
        restrict: 'E', templateUrl: 'app/directives/dashboardv2/dashboardv2.html', scope: {
            api: '=', //exposable api
            laatMeekijken: '&onMeekijken' //dashboardv2 on-meekijken=functie<model>
        }, controller: ['$scope', '$q', '$interval', 'api', function ($scope, $q, $interval, api) {
            let intervalId = null;
            let datacounter = 1;
            let newmodels = []; //modellen waarvan we acties binnenkrijgen terwijl we ze niet kenden: doen we 1 keer een herberekening voor, daarna moet het kloppen zolang de tijd niet wijzigt


            function init() {
                //we halen uit sessiondata, om te zorgen dat settings ook over reloads blijven

                //de standaard
                $scope.form = {
                    grid: 2,
                    sorteer: 'az' //sorteervolgorde az, aantal, fout
                };

                try {
                    const sessieform = sessionStorage.getItem("dashboardv2");
                    if (sessieform) {
                        $scope.form = Object.assign($scope.form, JSON.parse(sessieform));
                        log.debug(`Opgeslagen form, gecombineerd`, $scope.form);
                    }
                } catch (e) {
                    //mislukt, jammer dan
                    log.debug(e);
                }
                $scope._open = false; //zijn we open?
                $scope.graphdata = {}; //modeldata
                $scope.models = []; //sorted models

                $scope.startstr = null;
                $scope.endstr = null;
                $scope.$watch('form', function () {
                        //opslaan in de sessie
                        log.debug(`FORM GEWIJZIGD`, $scope.form);
                        sessionStorage.setItem('dashboardv2', JSON.stringify($scope.form));
                    },
                    true //deep
                );
            }

            //api
            $scope.api = {
                //open het dashboard
                open() {
                    if (!(api.userdata && api.userdata.dashboard2)) {
                        return; //gaan we niet doen
                    }
                    log.debug(`Dashboard open`);
                    if (!$scope._open) {
                        $scope._open = true;
                        api.send('dashboard', 'subscribev2'); //die doet de rest
                        //eerste keer?
                        if (!$scope.form.startdate) {
                            $scope.resetNu(); //default
                        } else {
                            setDatestrings(true); //force rebuild
                        }
                    }
                },

                close() {
                    if ($scope._open) {
                        $scope._open = false;
                        api.send('dashboard', 'unsubscribe');
                        if (intervalId) {
                            $interval.cancel(intervalId);
                        }
                    }
                }
            }

            //////////////////// NOTIFICATIONS /////////////////////////////
            $scope.$on('api.notify.registratieVerzoek', _ => {
                //er is blijkbaar een hickup geweest. We gaan opnieuw registreren
                if ($scope._open) {
                    api.send('dashboard', 'subscribev2'); //opnieuw subscriben
                }
            });


            $scope.$on('$destroy', _ => {
                //unsubscribe etc
                if ($scope._open) {
                    $scope.api.close(); //handel ook unsubscribe etc af
                }
            });

            $scope.$on('api.notify.dashboardlive', async (_e, live) => {
                //livemodellen gewijzigd

                if ($scope._open) {
                    log.debug(`LIVE`, _e, live);
                    await checkNewModels(live);//nieuwe? Dan opnieuw data ophalen
                    //in live zitten alle livemodellen
                    for (let m of $scope.models) {
                        m.live = live.includes(m.id);
                    }
                    api.updateChanges(); //bijwerken
                }
            });


            $scope.$on('api.notify.dashboardactionsv2', (_e, data) => {
                if (!$scope._open) {
                    return;
                }
                log.debug(`dashboardactions`, data);
                //extra data voor het model. Toevoegen
                if ($scope.graphdata[data.model]) {
                    $scope.graphdata[data.model].actions.push(...data.actions);
                    $scope.graphdata[data.model].errors.push(...data.errors);
                    redraw(data.model); //doorwerken
                } else {
                    //we kennen dit model niet in graphdata
                    //misschien is het nieuw. Checken dus
                    checkNewModels([data.model]);
                }
            });

            /**
             * Check of er bij ids modellen zitten die we niet kennen. Die gaan we één keer checken op relevantie.
             * @param ids
             */
            async function checkNewModels(ids) {
                ids = ids.filter(id => (!$scope.graphdata[id]) && (!newmodels.includes[id]));
                if (ids.length) {
                    newmodels.push(...ids);
                    await rebuildGraphdata();
                    api.updateChanges();
                    for (let id of ids) {
                        await redraw(id);
                    }
                }
            }

            /**
             * Zet de scopestrings voor de modelaction components
             * @param {boolean} [force] Force full reset
             */
            async function setDatestrings(force) {
                log.debug(`setDatestrings`);
                if ($scope.form.startdate && $scope.form.starttime) {
                    $scope.startstr = `${$scope.form.startdate} ${$scope.form.starttime}`;
                } else {
                    $scope.startstr = moment().format('YYYY-MM-DD HH:mm');
                }

                if ($scope.form.enddate && $scope.form.endtime) {
                    $scope.endstr = `${$scope.form.enddate} ${$scope.form.endtime}`;
                } else {
                    $scope.endstr = moment().add(2, 'hours').format('YYYY-MM-DD HH:mm');
                }
                if ($scope.endstr < $scope.startstr) {
                    let endstr = $scope.startstr;
                    $scope.startstr = $scope.endstr;
                    $scope.endstr = endstr;
                }
                await sihwWait(rebuildGraphdata(force)); //get the new data (async)
                //en redraw alles
                redraw();
            }

            $scope.dateChanged = setDatestrings;


            /**
             * Reset de tijd op alles, daarvoor moeten we dus op zoek naar het begin van dit project
             */
            $scope.resetAlles = function () {
                //aangezien alleen end er toe doet voor het ophalen
                //zetten we start op iets weirds
                Object.assign($scope.form, {
                    startdate: '1999-12-31',
                    starttime: "23:59",
                    enddate: moment().add(2, 'days').format('YYYY-MM-DD'),
                    endtime: "00:00"
                });
                setDatestrings(true);
            }

            $scope.resetNu = function () {
                let now = moment();
                let end = moment().add(2, 'hours');
                Object.assign($scope.form, {
                    startdate: now.format('YYYY-MM-DD'),
                    starttime: now.format('HH:mm'),
                    enddate: end.format('YYYY-MM-DD'),
                    endtime: end.format('HH:mm'),
                });
                setDatestrings(true);
            }

            $scope.modelFilter = function (model) {
                if (!($scope.form.filter?.length)) {
                    return true;
                }
                return `${model.user} - ${model.titel}`.toLowerCase().includes($scope.form.filter.toLowerCase());
            }

            /**
             * Laat canvascontroller weten dat er meegekeken moet worden met het detail
             */
            $scope.meekijken = function (model) {
                if (! api.userdata?.meekijker)
                {
                    return; //mag niet
                }
                log.debug(`Stuur meekijken-event`, model.id, model);
                const meekijkid = model.samenwerking === 'slave' ? model.samenwerking_main : model.id;
                if (meekijkid) {
                    $scope.laatMeekijken({model: meekijkid}); //bij samenwerking de main bekijken
                    $scope.api.close(); //wij dicht
                } else {
                    api.toast('DASHBOARDV2.KANMODELNIETOPENEN', "error");
                }
            }

            /**
             * Verzoek om alle modelaction-grafieken te hertekenen
             */
            $scope.forceRedraw = function () {
                for (let model of $scope.models) {
                    setTimeout(() => {
                        $scope.$broadcast(`modelaction_redraw_${model.id}`);
                    }, 0); //even async, zodat de rest doorwerkt
                }
            }


            /**
             * Regel een redraw van het opgegeven modelId, of als null, alle
             * @param [modelId]
             */
            async function redraw(modelId) {
                //async, zodat ux kan bijwerken, altijd met een pauze
                await new Promise(resolve => setTimeout(resolve, 300));
                log.debug(`DO redraw ${modelId || 'alles'}`);
                if (modelId) {
                    $scope.$broadcast(`modelaction_redraw_${modelId}`);
                } else {
                    //doe elk model en pauzeer steeds even voor ux
                    for (let model of $scope.models) {
                        if ($scope.form.live && !model.live) {
                            continue; //deze hoeft niet
                        }
                        $scope.$broadcast(`modelaction_redraw_${model.id}`);
                        await new Promise(resolve => setTimeout(resolve, 10));
                    }
                    api.updateChanges();
                }
            }

            /**
             * Get and rebuild fulll data
             * @param {boolean} [force] Force full reset
             */

            async function rebuildGraphdata(force) {
                //async, so we have to do some $q at the end
                log.debug(`rebuildGraphdata`, force);
                let currentDatacounter = ++datacounter;
                let relevantModels = (await api.send('dashboard', 'v2relevantModels', {end: $scope.endstr})).models;
                if (datacounter !== currentDatacounter) {
                    //niet meer de meest recente versie
                    return;
                }
                log.debug(`RelevantModels`, relevantModels);
                if (force) {
                    $scope.models = [];
                    newmodels = [];
                    $scope.graphdata = {};
                }
                //als we een model al hebben, hebben we ook alle actiedata, dus alleen nieuwe ophalen
                let newids = [];
                for (let model of relevantModels) {
                    if (!$scope.graphdata[model.id]) {
                        //dan dus ook niet in models
                        $scope.models.push(model);
                        $scope.graphdata[model.id] = {
                            actions: [], errors: []
                        };
                        newids.push(model.id);
                    }
                }
                if (newids.length) {
                    //haal de actiondata op voor de nieuwe, altijd alle
                    let actiondata = await api.send('dashboard', 'v2actiondata', {ids: newids});
                    for (let id of newids) {
                        //dat zit nu in actiondata
                        $scope.graphdata[id] = actiondata[id];
                        //hoeft geen redraw, want wordt zo door ux gemaakt en dan volgt er al een draw
                    }
                }
                log.debug(`graphdata gezet!`);

                //startdate nooit eerder dan oudste model
                if ($scope.models.length) {
                    let oudste = "2999-12-31 00:00:00";
                    for (let m of $scope.models) {
                        if (m.createdAt < oudste) {
                            oudste = m.createdAt;
                        }
                    }
                    let m_oudste = moment(oudste);
                    if (moment(`${$scope.startstr}:00`).isBefore(m_oudste)) {
                        //wijzigen
                        log.debug(`Aanpassen startdate want ${$scope.startstr}:00 < ${oudste}`);

                        $scope.form.startdate = m_oudste.format('YYYY-MM-DD');
                        $scope.form.starttime = m_oudste.format('HH:mm');
                        $scope.startstr = m_oudste.format('YYYY-MM-DD HH:mm');
                    }
                }
                //sorteren
                sorteerModellen();
            }

            /**
             * Sorteer de modellen volgens form.sorteer
             */
            function sorteerModellen() {
                $scope.form.sorteer = $scope.form.sorteer || 'az'; //set een default als nodig

                /**
                 * Standaardfunctie voor alfabetisch
                 * @param a
                 * @param b
                 * @returns {number}
                 */

                let key = null;
                switch ($scope.form.sorteer) {
                    //we schrijven het uit voor toekomstige uitbreidingen
                    case 'aantal':
                        key = 'aantal';
                        break;
                    case 'fout':
                        key = 'fout';
                        break;
                    //anders geen key = alleen alfabet
                }
                const laatsteerrordatapermodel = {}; //op model-id de laatste relevante errordata
                const laatsteactiepermodel = {}; //op model-id de laatste actie binnen het tijdbestak
                for (let m of $scope.models) {
                    if ($scope.graphdata[m.id]?.errors?.length) {
                        //er valt wat te kiezen
                        if (!$scope.endstr) {
                            laatsteerrordatapermodel[m.id] = $scope.graphdata[m.id].errors[$scope.graphdata[m.id].errors.length - 1];
                        } else {
                            laatsteerrordatapermodel[m.id] = $scope.graphdata[m.id].errors.findLast(error => error.moment < $scope.endstr);
                        }
                    }
                    //anders gewoon niet
                    if ($scope.graphdata[m.id]?.actions?.length)
                    {
                        if ($scope.endstr)
                        {
                            //laatste relevante actie dus voor die enstr
                            laatsteactiepermodel[m.id] = $scope.graphdata[m.id].actions.findLast(action => action.moment < $scope.endstr);
                        }
                        else {
                            laatsteactiepermodel[m.id] = $scope.graphdata[m.id].actions[$scope.graphdata[m.id].actions.length - 1];
                        }
                    }
                }
                $scope.models.sort((a, b) => {
                    let volgorde = 0;
                    if ($scope.form.sorteer === 'begin') {
                        volgorde = ($scope.graphdata[a.id].actions[0]?.moment || "9999").localeCompare($scope.graphdata[b.id].actions[0]?.moment || "9999");
                    }
                    else if($scope.form.sorteer === 'eind') {
                        //op volgorde van laatste actie
                        volgorde = (laatsteactiepermodel[b.id]?.moment || "0000").localeCompare(laatsteactiepermodel[a.id]?.moment || "0000")
                    }
                    else if (key) {
                        //sorteer eerst daarop. Meeste eerst
                        let numa, numb;
                        numa = laatsteerrordatapermodel[a.id]?.count[key] || 0;
                        numb = laatsteerrordatapermodel[b.id]?.count[key] || 0;
                        volgorde = numb - numa;
                    }
                    if (volgorde === 0) {
                        //sorteer op alfabet
                        volgorde = `${a.user}-${a.titel}`.localeCompare(`${b.user}-${b.titel}`)
                    }
                    return volgorde < 0 ? -1 : 1; //normaliseren
                });
/*                log.info(`Gesorteerd:`);
                for(let model of $scope.models.slice(0,9)) {
                    let gd = $scope.graphdata[model.id];
                    log.info(gd.actions[0].moment,gd.actions[0].action,model.user, model.titel);
                }*/
            }

            $scope.sorteerModellen = sorteerModellen;

            //init
            init();
        }]
    }
}]);
